import React from "react"
import { Link } from "gatsby"

const ServiceSidebarArchitecture = ({ location }) => {
  const links = [
    { url: "/sd-web-architecture", title: "Web Architecture Design" },
    { url: "/sd-mobile-architecture", title: "Mobile Architecture Design" },
    { url: "/sd-backend-architecture", title: "Backend Architecture Design" },
    { url: "/sd-backend-integration", title: "Backend Module Integration" },
    { url: "/sd-migration-replication", title: "Migration & Replication" },
    { url: "/sd-system-monitoring", title: "System Monitoring Design" },
    { url: "/sd-security-design", title: "Security Design" },
    { url: "/sd-api-gateway-design", title: "API Gateway Design" },
    { url: "/sd-devops-design", title: "DevOps & Reliability Design" },
  ]

  // Get current pathname to mark active link on sidebar.
  const current_url = location.pathname ? location.pathname : ""

  return (
    <div className="services-details-info">
      <ul className="services-list">
        <li>
          {links.map(link => (
            <Link
              to={link.url}
              className={current_url === link.url ? "active" : ""}
            >
              {link.title}
            </Link>
          ))}
        </li>
      </ul>
    </div>
  )
}

export default ServiceSidebarArchitecture
