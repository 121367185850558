import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-integration.jpg"

const BackendIntegrationContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>

              <h3>Backend Module Integration</h3>
              <h5>
                Design of interfaces between microservices and project external
                entities such as client 3rd party systems, internal business
                core systems, monitoring, business inteligence, etc.
              </h5>

              <p>
                Integration between internal components like modules or
                microservices in a solution is very important for an efficient
                and fast platform. Many aspects have to be considered like:
                latency, throughput, number of API calls, security, encryption,
                discoverability and load balancing. There are many architectural
                decisions to be made about communication, security, redundancy
                and resilience.{" "}
              </p>
              <p>
                With our extensive experience in internal and external
                communication patterns, the AFS team can guide your project to
                make proper decisions early on. This allows you to minimize
                additional costs caused by design errors and potential pivoting
                to another technology later in the project. We also provide
                examples and guidance in the development process that will
                smooth adoption of a new technology. No more doubling of project
                budget and time due to wrong architecture decisions!
              </p>
              <p>
                Specifically, we provide expertise in 3rd party integration
                using SOAP Services, REST API, OData API, SAP ES, Asynchronous
                messaging like Kafka & RabbitMQ and legacy file interfaces if
                necessary, landscape integration using ESB, Process Integration
                Engines, Business Rule Engines, Product Information Management
                Systems, etc.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Typical technical and organizational challenges with
                      integration we see projects struggle with:
                    </h3>
                    <ul>
                      <li>
                        Lack of flexibility in process development, need to
                        externalise business customisation to non-developer
                        tools like business rules engine, process engine
                      </li>
                      <li>
                        Unstandardised API between internal components causing
                        high dev. effort
                      </li>
                      <li>
                        No proper service contracts with 3rd party integrations
                      </li>
                      <li>
                        No interface monitoring for support purposes and 3rd
                        party contract accountability
                      </li>
                      <li>
                        Lack of proper communication strategy with internal and
                        external parties about interface changes which in turn
                        can cause development delay or even production
                        disasters.
                      </li>
                      <li>
                        Challenge in program management planning where projects
                        have to be synched because of many interface
                        dependencies
                      </li>
                      <li>
                        No proper encryption or SSL cert checks in interfaces
                      </li>
                      <li>
                        No strategy for disaster recovery in asynchronous
                        communication scenarios
                      </li>
                    </ul>
                    <h5 className="pt-30">
                      If you feel that you suffer in your project due to any of
                      these points, we can help.
                    </h5>
                  </div>
                </div>
              </div>

              <div className="content">
                <h3>What we can do to solve these challenges:</h3>
                <ul>
                  <li>
                    Prepare an Integration Design to address specific
                    integration needs
                  </li>
                  <li>
                    Prepare an Integration Best Practices Catalog for future
                    integrations
                  </li>
                  <li>
                    Train developers to adhere to project integration standards
                  </li>
                  <li>
                    Introduce proper integration framework for interface
                    contracts within solution
                  </li>
                  <li>
                    Create a standardised process for integrating 3rd party
                    providers and clients
                  </li>
                  <li>
                    Introduce interface dependency management at the
                    organisational program/project level
                  </li>
                  <li>
                    Build interface monitoring to catch potential problems early
                    on
                  </li>
                </ul>
              </div>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our backend
                integration projects.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>SpringBoot</span>
                </li>
                <li>
                  <span>NodeJS</span>
                </li>
                <li>
                  <span>Snowplow</span>
                </li>
                <li>
                  <span>PLSQL</span>
                </li>
                <li>
                  <span>Apache Nifi/Camel</span>
                </li>
                <li>
                  <span>Kafka/Kinesis</span>
                </li>
                <li>
                  <span>SOAP</span>
                </li>
                <li>
                  <span>REST API</span>
                </li>
                <li>
                  <span>oData</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BackendIntegrationContent
